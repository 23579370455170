import React from 'react';

import { PAGES } from '../../../utils/utils';
import Construction from '../../../components/en/expertise/construction';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const ConstructionPage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_CONSTRUCTION}>
    <SEO title="Construction" lang="en" />
    <Construction />
  </Layout>
);

export default ConstructionPage;

import React from 'react';

import { getYearsSinceOfficeBirth } from '../../../utils/utils';
import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const Construction = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.CONSTRUCTION}>
      <h1>Construction</h1>

      {generateBreadcrumb('construction')}
      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="construction"
          src="/img/competences_sprite.jpg"
          alt="Construction"
        />
      </div>

      <p>
        For{' '}
        <span className="accent">
          <span id="anneesExp">{getYearsSinceOfficeBirth()}</span> years
        </span>
        , our firm has represented all different players in the field of
        construction :{' '}
        <span className="accent2">owners, contractors, subcontractors,</span>{' '}
        etc. Our experience touches all types of residential and commerical
        construction projects. We will help you with{' '}
        <span className="accent">latent defects</span>,{' '}
        <span className="accent">legal hypothecs</span> and{' '}
        <span className="accent">suretyship</span>.
      </p>
    </ExpertiseContainer>
  );
};

Construction.propTypes = {};

export default Construction;
